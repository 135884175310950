import React, {useEffect, useState} from 'react';
import {Link, graphql} from 'gatsby';
import cx from 'classnames';
import {Swiper, SwiperSlide} from 'swiper/react';

import {Breadcrumb, Card, Layout, Post, Seo, Share} from '@app/components';
import {
  query,
  collection,
  where,
  getDocs,
  orderBy,
  limit,
} from 'firebase/firestore';
import {db} from '../../firebase';
import slugify from 'slugify';

type PageTemplatePropTypes = {
  pageContext: {
    language: string;
    title: string;
  };
};

const PageTemplate: React.FC<PageTemplatePropTypes> = ({pageContext}) => {
  const [post, setPost] = useState({
    frontmatter: {
      title: '',
      author: '',
      categories: [],
      date: '',
      imageUrl: '',
    },
    html: '',
  });
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const parameter = params.get('title');

    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('titleUrl', '==', parameter),
        where('language', '==', pageContext.language),
        limit(1),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = {
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            categories: doc.data().categories,
            date: doc.data().createAt.toDate().toLocaleString(),
            imageUrl: doc.data().imageUrl,
          },
          html: doc.data().article,
        };

        setPost(data);
        setIsLoad(true);
      });
    };

    unSub();
  }, []);

  return (
    <Layout>
      <Seo title={post?.frontmatter.title} />
      <div
        className={cx(
          'container mx-auto px-4 py-20 xl:max-w-container-xl lg:px-0',
          {'opacity-0': !isLoad},
        )}
      >
        <div className="grid lg:grid-cols-12 gap-12">
          <div className="lg:col-span-8">
            <Post.Cover
              title={post?.frontmatter.title}
              author={post?.frontmatter.author}
              categories={post?.frontmatter.categories}
              date={post?.frontmatter.date}
              cover={
                post?.frontmatter.pdf?.publicURL
                  ? null
                  : post?.frontmatter.cover?.childImageSharp.fluid
              }
              imageUrl={post?.frontmatter?.imageUrl}
            />
            <Post.Content content={post?.html} />
            <div className="mt-12 mb-20">
              <Share title={post?.frontmatter.title} />
            </div>
            <hr />
            {post?.frontmatter.tags && (
              <div className="tag">
                {post?.frontmatter.tags.map((tag) => (
                  <Link
                    to={`/tag/${slugify(tag, {
                      lower: true,
                      strict: true,
                    })}`}
                    rel="tag"
                    className="tag__item"
                    key={tag}
                  >
                    {tag}
                  </Link>
                ))}
              </div>
            )}

            <div className="pattern-large mt-10" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
